


<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53" height="53" viewBox="0 0 47 47">
      <g id="Group_760" data-name="Group 760" transform="translate(-19.5 -644.5)">
        <g transform="matrix(1, 0, 0, 1, 16.5, 641.5)">
          <g id="Ellipse_36-2" data-name="Ellipse 36" transform="translate(4.5 4.5)" fill="none" stroke="rgba(247,247,247,0.6)" stroke-width="1">
            <circle cx="22" cy="22" r="22" stroke="none"/>
            <circle cx="22" cy="22" r="21.5" fill="none"/>
          </g>
        </g>
        <g id="like_1_" data-name="like (1)" transform="translate(32.774 659.927)">
          <path :class="isClicked ? 'fill' : 'no-fill'" id="Path_233" data-name="Path 233" d="M20.715,7.4a.6.6,0,0,1,.054.249v2.461a2.464,2.464,0,0,1-2.461,2.461H13.384v3.692a2.464,2.464,0,0,1-2.461,2.461H9.077a.616.616,0,0,1-.615-.615V15.2l-2.38-4.166A.605.605,0,0,1,6,10.731V2.115A.616.616,0,0,1,6.615,1.5h9.877a2.461,2.461,0,0,1,2.248,1.462Z" transform="translate(-1.077 -1.5)" fill="rgba(247,247,247,0.6)"/>
          <path :class="isClicked ? 'fill' : 'no-fill'" id="Path_234" data-name="Path 234" d="M.615,10.5H3.692v9.846H.615A.616.616,0,0,1,0,19.731V11.115A.616.616,0,0,1,.615,10.5Z" transform="translate(0 -10.5)" fill="rgba(247,247,247,0.6)"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ["isClicked"],
  data() {
    return {}
  }
};
</script>

<style scoped>
svg{
  display:block;
  width:3vw;
  width:var(--icon-width);
  height: 3vw;
  height:var(--icon-width);
}
.a,
.d {
  fill: none;
}
.a {
  stroke: rgba(247, 247, 247, 0.6);
}
.b {
  fill: rgba(247, 247, 247, 0.6);
}
.c {
  stroke: none;
}
</style>